<template>
  <div class="page-content w-100">
   <div class="row">
     <div class="col-sm-12">
       <p class="text-center page-heading">Finance manager</p>
     </div> 
   </div>
   <div class="row">
       <div class="col-sm-12">
            <h4><router-link to="/admin/financemanager" class="text-dark"><font-awesome-icon :icon="['fas', 'long-arrow-alt-left']"/></router-link><span class="text-color"> Unit amounts</span></h4>
            <div class="hr-border"></div>
       </div>
   </div>
   <div>
       <h5 class="text-color my-5">View amounts by location</h5>
       <div class="row my-1">
           <div class="col-lg-3 col-6">
             <div class="form-group">
              <label class="text-center text-color">State</label>
              <input class="form-control" type="text" name="state" id="state">
             </div>
           </div>
            <div class="col-lg-3 col-6">
             <div class="form-group">
              <label class="text-center text-color">Postal district</label>
              <input class="form-control" type="text" name="district" id="district">
             </div>
           </div>
            <div class="col-lg-3 col-6">
             <div class="form-group">
              <label class="text-center text-color">Postal sub district</label>
              <input class="form-control" type="text" name="subdistrict" id="subdistrict">
             </div>
           </div>
            <div class="col-lg-3 col-6">
               <button class="btn btn-blue-color text-white mt-4"><font-awesome-icon :icon="['fas', 'edit']"/></button>
           </div>
       </div>
        <div class="row my-1">
           <div class="col-lg-3 col-6">
             <div class="form-group">
              <input class="form-control" type="text" name="state1" id="state1">
             </div>
           </div>
            <div class="col-lg-3 col-6">
             <div class="form-group">
              <input class="form-control" type="text" name="district1" id="district1">
             </div>
           </div>
            <div class="col-lg-3 col-6">
             <div class="form-group">
              <input class="form-control" type="text" name="subdistrict1" id="subdistrict1">
             </div>
           </div>
            <div class="col-lg-3 col-6">
               <button class="btn btn-blue-color text-white"><font-awesome-icon :icon="['fas', 'edit']"/></button>
           </div>
       </div>
        <div class="w-100 text-center my-5">
          <button class="btn btn-blue-color text-white" style="border-radius: 10px;">Create task for missing location</button>
        </div>
   </div>
      <div class="hr-border"></div>
      <div class="my-3">
           <div class="row my-1">
           <div class="col-lg-3 col-6">
             <div class="form-group">
              <label class="text-center text-color">Finance head</label>
              <input class="form-control" type="text">
             </div>
           </div>
            <div class="col-lg-3 col-6">
             <div class="form-group">
              <label class="text-center text-color">Amount</label>
              <input class="form-control" type="text">
             </div>
           </div>
            <div class="col-lg-3 col-6">
             <div class="form-group">
              <label class="text-center text-color">Start date</label>
              <input class="form-control" type="date">
             </div>
           </div>
            <div class="col-lg-3 col-6">
               <button class="btn btn-blue-color text-white mt-4"><font-awesome-icon :icon="['fas', 'edit']"/></button>
           </div>
       </div>
         <div class="row my-1">
           <div class="col-lg-3 col-6">
             <div class="form-group">
              <input class="form-control" type="text">
             </div>
           </div>
            <div class="col-lg-3 col-6">
             <div class="form-group">
              <input class="form-control" type="text">
             </div>
           </div>
            <div class="col-lg-3 col-6">
             <div class="form-group">
              <input class="form-control" type="date">
             </div>
           </div>
            <div class="col-lg-3 col-6">
               <button class="btn btn-blue-color text-white"><font-awesome-icon :icon="['fas', 'edit']"/></button>
           </div>
       </div>
        <div class="w-100 text-center my-5">
          <button class="btn btn-blue-color text-white" style="border-radius: 10px;margin-bottom:30px;">Create task for missing heads</button>
        </div>
      </div>
  </div>

       
</template>
<style>
label.text-color{
  width: auto;
}
</style>